import revive_payload_client_ZQdE0VmYZB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_l6vc4mco1l from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hbylQ8Ca98 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wstOJB0uYL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.4_sass@1.79._ygv5qy6cpl5f6qlppccpvm64bu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_q5qlJwzp9p from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uEo4eHUocD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_klUzWcY0EV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/web/.nuxt/components.plugin.mjs";
import prefetch_client_CoUuSo678H from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__q4yptnvfkocbae7jfeky3ngcmi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5ILycUeLBy from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.4_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.4_sass@1.79.4_terse_gagm4iah5mes3x3fvbaazvseze/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import imgwin_client_tXoijAZDSC from "/opt/buildhome/repo/web/plugins/imgwin.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/web/plugins/sentry.client.ts";
import vue_final_modal_pML93k5qcp from "/opt/buildhome/repo/web/plugins/vue-final-modal.ts";
import vue_gtm_client_stBjLl0OeM from "/opt/buildhome/repo/web/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_ZQdE0VmYZB,
  unhead_l6vc4mco1l,
  router_hbylQ8Ca98,
  _0_siteConfig_wstOJB0uYL,
  payload_client_q5qlJwzp9p,
  navigation_repaint_client_uEo4eHUocD,
  chunk_reload_client_klUzWcY0EV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CoUuSo678H,
  plugin_5ILycUeLBy,
  imgwin_client_tXoijAZDSC,
  sentry_client_shVUlIjFLk,
  vue_final_modal_pML93k5qcp,
  vue_gtm_client_stBjLl0OeM
]